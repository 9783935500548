import React, { Component } from 'react';

import Modal from '../../components/UI/Modal/Modal';
import Aux1 from '../Aux1/Aux1';

const withErrorHandler = ( WrappedComponent, axios ) => {
    return class extends Component {
        // state = {
        //     error: null
        // }
        constructor(props) {
            super(props);
            this.state = {
                error: null,
            };
            this.reqInterceptor = axios.interceptors.request.use( req => {
                this.setState( { error: null } );
                return req;
            } );
            this.resInterceptor = axios.interceptors.response.use( res => res, error => {
                this.setState( { error: error } );
            } );
        }

        componentWillUnmount () {
            axios.interceptors.request.eject( this.reqInterceptor );
            axios.interceptors.response.eject( this.resInterceptor );
        }

        errorConfirmedHandler = () => {
            this.setState( { error: null } );
        }

        render () {
            return (
                <Aux1>
                    <Modal
                        show={this.state.error}
                        modalClosed={this.errorConfirmedHandler}>
                        {this.state.error ? this.state.error.message : null}
                    </Modal>
                    <WrappedComponent {...this.props} />
                </Aux1>
            );
        }
    }
}

export default withErrorHandler;