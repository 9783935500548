import React, { Component } from "react";
import { Route, Redirect } from 'react-router-dom';

class App extends Component {
   state={
       redirectURL:""
   }

  componentDidMount() {
    const windowUrl = window.location.search;
   const sp = new URLSearchParams(windowUrl);
   if(sp.has("token"))
   {
        this.setState({redirectURL:"/auth"})
   }
  }

  render() {
    if(this.state.redirectURL !="")
    {
        // return <Redirect to={this.state.RedirectPath}/>
        return <Redirect to={{
          //search:(this.state.Tab == "CheckOut2"?this.state.bookingID:this.state.Tab),
            pathname: this.state.redirectURL,
            //state: { stateName: this.state.anyState}
            //state: (this.state)
            //state: this.state
          }} />;
    }
    return <div>test</div>;
  }
}
export default App;
