import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

class navigationItem extends Component {
// const navigationItem = ( props ) => (
    CloseNav = () =>{
        if(document.getElementsByClassName("navbar-toggler")[0].className !== "navbar-toggler collapsed")
        {
        document.querySelector('.navbar-toggler').click();
        }
    }
    render() {
        return (
    <li className={(this.props.children.toLowerCase()).replace(/\s/g,'')}>
        <NavLink 
            onClick={()=>this.CloseNav()}
            to={this.props.link}
            exact={this.props.exact}>{this.props.children}</NavLink>
            {/* <NavLink className="nav-link" to="/products">Products</NavLink> */}
    </li>
);
        }
    }

export default navigationItem;
